import "../styles/globals.scss";

import IntegrationComponent from "@components/IntegrationComponents";
import GoogleSignIn from "@components/IntegrationComponents/GoogleSignIn";
import Providers, { defaultIntercomData } from "@components/Providers";
import { useShouldShowFooterFeed } from "@hooks/useShouldShowFooterFeed";
import useSocket from "@hooks/useSocket";
import useSocketManager from "@hooks/useSocketManager";
import { useUserDetails, useUserSession } from "@hooks/useUserSession";
import API from "@lib/api/api";
import PubSub, { EVENTS, usePubSub, useSubscribe } from "@lib/pubsub";
import { BreadcrumbsSchema } from "@lib/tools/breadcrumbs";
import { getDeviceInfo, scrollTop, useLocale } from "@lib/tools/helpers";
import { logError } from "@lib/tools/logger";
import { useNextCssRemovalPrevention } from "@lib/tools/useNextCSSFix";
import { ErrorBoundary } from "@sentry/nextjs";
import { pageLoadingAtom } from "@store/pageLoading";
import { useAtom } from "jotai";
import dynamic from "next/dynamic";
import { Montserrat, Oswald } from "next/font/google";
import Head from "next/head";
import { useRouter as useNav, usePathname } from "next/navigation";
import { useRouter } from "next/router";
import Script from "next/script";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactGA from "react-ga4";
import { useIntercom } from "react-use-intercom";
import useSWR from "swr";
import MicrosoftClarity from "./MicrosoftClarity";
import Tracking from "./Tracking ";
import Turnstile from "./Turnstile";
import { useHydrateAtoms } from "jotai/utils";
import { requestTimeAtom } from "@store/global";

import JotaiHydration from "@components/jotai-hydration";

const font = Montserrat({
  subsets: ["latin"],
  fallbacks: ["sans-serif"],
  preload: true,
  variable: "--main-font",
});

const oswald = Oswald({
  subsets: ["latin"],
  weight: ["300", "400", "700"],
  variable: "--oswald-font",
});

const Chat = dynamic(() => import("@components/Chat"));
const MainBodyWrapper = dynamic(() => import("@items/MainBodyWrapper"));
const IntercomCustomButton = dynamic(
  () => import("@components/IntercomCustomButton")
);
const Popup = dynamic(() => import("../components/Popup/Popup"));
const PageChange = dynamic(() => import("../items/PageChange"));
const Header = dynamic(() => import("../components/Header"));
const Footer = dynamic(() => import("../components/Footer"));
const NavSideBar = dynamic(() => import("../components/NavSideBar"));
const Modal = dynamic(() =>
  import("@components/Modal").then((mod) => mod.Modal)
);

const enable_logs = process.env.NEXT_PUBLIC_ENABLE_LOGS;

// https://github.com/vercel/next.js/issues/33286
// https://github.com/vercel/next.js/issues/17464#issuecomment-1537262075
const applyPreloadStylesheetLinks = () => {
  const preloadStylesheetLinks = document.querySelectorAll(
    "link[rel='preload'][as='style']"
  );
  preloadStylesheetLinks.forEach((preloadLink) => {
    const existingStylesheetLink = document.querySelector(
      `link[rel='stylesheet'][href='${preloadLink.href}']`
    );

    if (!existingStylesheetLink) {
      const stylesheetLink = document.createElement("link");
      stylesheetLink.rel = "stylesheet";
      stylesheetLink.href = preloadLink.href;
      document.head.appendChild(stylesheetLink);
    }
  });
};

const idfn = () => ({});

function MyApp(props) {
  const { Component, pageProps } = props;

  // you cannot easily update the recoil state outside of a component
  // so this is a workaround to pass the init dictionary to the components e.g. Language.jsx
  if (!PubSub.state[EVENTS.DICT])
    PubSub.publishSync(EVENTS.DICT, pageProps.dictionary);

  if (!PubSub.state[EVENTS.PROVIDERS])
    PubSub.publishSync(EVENTS.PROVIDERS, pageProps.providers);

  if (!PubSub.state[EVENTS.MOBILE])
    PubSub.publishSync(EVENTS.MOBILE, pageProps.isMobile);

  if (!PubSub.state[EVENTS.RACES])
    PubSub.publishSync(EVENTS.RACES, pageProps.races);

  useHydrateAtoms([[requestTimeAtom, pageProps.requestTime]]);

  useNextCssRemovalPrevention(); // very important to prevent css removal, do not remove
  const router = useRouter();
  const { replace } = useNav();
  const pathname = usePathname();
  const locale = useLocale();
  const [chat, setChat] = useState(false);
  const [blockStatus, setBlockStatus] = useState("check");
  const [miniSide, setMiniSide] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const sports = usePubSub(EVENTS.IS_SPORTSBOOK);
  const [userLogged, setUserLogged] = useState(false);
  const session = useUserSession();
  const { update } = useIntercom();
  const bodyContentContainerRef = useRef(null);
  const activeGA = useRef(false);
  const caseBattlesSocket = useSocketManager();
  const shouldShowFooter = useShouldShowFooterFeed();
  const lastPathname = useRef(
    locale === "en" ? pathname : `/${locale}${pathname}`
  );
  const isChallenges = pathname.includes("/challenges");
  const userCurrency = session?.userData?.currency?.code || "USD";
  const [pageLoading] = useAtom(pageLoadingAtom);

  const isMobileLandingPage = useMemo(
    () => pathname?.includes("ph-mobile"),
    [pathname]
  );

  const races = useSWR(
    ["public/active-races", userCurrency],
    ([url, currency]) => API.post(url, { currency }).then((r) => r.data),
    {
      fallbackData: pageProps.races,
      revalidateOnMount: !pageProps?.races,
      revalidateOnFocus: false,
      refreshInterval: 60 * 60 * 1000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    PubSub.publishSync(EVENTS.RACES, races.data);
  }, [races.data]);

  const clearUrlQueries = useCallback(
    () => replace(pathname, { scroll: true }),
    [pathname, replace]
  );
  useSubscribe(EVENTS.REMOVE_QUERY, clearUrlQueries);

  useEffect(() => {
    try {
      const logs = enable_logs.split(",").filter((log) => log);
      const shouldEnable = logs.some((log) => pathname.includes(`/${log}`));

      // eslint-disable-next-line no-console
      console.log = shouldEnable ? window.log : () => {};
    } catch {}
  }, [pathname]);

  useEffect(() => {
    router.events.on("routeChangeComplete", applyPreloadStylesheetLinks);
    return () => {
      router.events.off("routeChangeComplete", applyPreloadStylesheetLinks);
    };
  }, [router.events]);

  const handleUserLoggedChange = (isLogged) => {
    setUserLogged(isLogged);
  };

  const openChat = useCallback((b) => {
    localStorage.setItem("chatState", b ? "true" : "false");
    setChat((item) => (b !== undefined ? b : !item));
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedChatState = localStorage.getItem("chatState");
      setChat(JSON.parse(savedChatState));
    }
  }, []);

  useEffect(() => {
    try {
      const logs = enable_logs.split(",").filter((log) => log);
      const shouldEnable = logs.some((log) => pathname.includes(`/${log}`));

      // eslint-disable-next-line no-console
      console.log = shouldEnable ? window.log : () => {};
    } catch {}
  }, [pathname]);

  useEffect(() => {
    const measurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
    const production = process.env.NEXT_PUBLIC_PRODUCTION;

    if (measurementId && production !== "false") {
      activeGA.current = true;
      ReactGA.initialize(measurementId);
    } else if (production === "true") {
      throw new Error(
        "Google Analytics Measurement ID is missing or not in production"
      );
    }
  }, []);

  //Send page view to Google Analytics
  useEffect(() => {
    if (activeGA.current)
      ReactGA.send({ hitType: "pageview", page: router.pathname });
  }, [router.pathname]);

  //Changes event listener for IOS devices
  useEffect(() => {
    if (typeof window !== "undefined") {
      const ua = navigator.userAgent;
      if (ua.match(/iPad|iPhone|iPod/)) {
        window.click = "touchstart";
      }
    }
  }, []);

  // Check access to the casino
  useEffect(() => {
    if (blockStatus === "check") {
      API.get("public/ip")
        .then((res) => {
          if (res.data.success) {
            let condition = res.data.details.conditions;
            setBlockStatus(condition);
            if (condition !== false) {
              router.push({
                pathname: router.pathname,
                query: {
                  ...router.query,
                  modal: "blocker",
                  type: condition,
                },
              });
            }
          } else {
            setBlockStatus(false);
          }
        })
        .catch((err) => {
          logError("IP ERROR", err);
          setBlockStatus(false);
        });
    }
  }, [blockStatus, router]);

  useEffect(() => {
    getDeviceInfo();
  }, []);

  useEffect(() => {
    const handleStart = (r) => {
      if (!r) return;
      const stripquery = r.split("?")[0];
      if (stripquery === lastPathname.current) return;

      setLoading(true);
    };

    const handleComplete = (r) => {
      if (!r) return;
      const stripquery = r.split("?")[0];
      setLoading(false);
      if (stripquery === lastPathname.current) return;

      scrollTop();
      lastPathname.current = stripquery;

      if (window.innerWidth < 1100) openChat(false);
    };

    const onError = (r) => {
      logError("Route change error", r);
      handleComplete("/");
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", onError);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", onError);
    };
  }, [openChat, router.events]);

  const chatSocket = useSocket(process.env.NEXT_PUBLIC_CHAT_SOCKET_URL, true);

  const { data: userDetails, mutate } = useUserDetails();
  const userLanguage = userDetails?.language;

  useEffect(() => {
    if (!userLanguage) return;
    if (userLanguage === locale) return;

    API.post("user/update-settings", {
      language: locale,
    }).then(() => mutate());
  }, [locale, mutate, userLanguage]);

  useEffect(() => {
    if (!userDetails?.intercom?.user_hash) return;

    update({
      ...defaultIntercomData,
      name: userDetails.username, // Full name
      email: userDetails.email, // Email address
      userId: userDetails.intercom.external_id,
      userHash: userDetails.intercom.user_hash,
      createdAt: userDetails.registered_time,
    });
  }, [update, userDetails]);

  useEffect(() => {
    if (typeof window !== "undefined")
      document.body.style.setProperty("--main-font", font.style.fontFamily);
  }, []);

  return (
    <div
      className={`site-container ${font.className}`}
      style={{
        "--main-font": font.style.fontFamily,
        "--oswald-font": oswald.style.fontFamily,
        "--vh": "calc(100vh * 0.01)",
      }}
    >
      <IntegrationComponent.FacebookPixel />
      <IntegrationComponent.GoogleAnalyticsGA4 />
      <IntegrationComponent.GoogleTagManagerBody />
      <IntegrationComponent.GoogleTagManagerHead />
      <GoogleSignIn />

      <Head>
        {locale === "pt" && <meta name="robots" content="noindex"></meta>}

        {/* Regular favicons */}
        <link
          rel="icon"
          type="image/png"
          sizes="64x64"
          href="https://cdn.rainbet.com/brand/favicons/64x64.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="128x128"
          href="https://cdn.rainbet.com/brand/favicons/128x128.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="https://cdn.rainbet.com/brand/favicons/192x192.png"
        />

        {/* Apple touch icons */}
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://cdn.rainbet.com/brand/favicons/120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://cdn.rainbet.com/brand/favicons/152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://cdn.rainbet.com/brand/favicons/180x180.png"
        />
      </Head>

      <Modal blockStatus={blockStatus} />
      <Popup />

      {blockStatus !== "block" && (
        <>
          {!isMobileLandingPage && (
            <Header
              chat={chat}
              miniSide={miniSide}
              setChat={openChat}
              setMenuToggle={setMenuToggle}
              setMiniSide={setMiniSide}
              onUserLoggedChange={handleUserLoggedChange} // Pass the function as a prop
            />
          )}
          <MainBodyWrapper
            chat={chat}
            miniSide={miniSide}
            sports={sports}
            mobileLanding={isMobileLandingPage}
          >
            <div
              ref={bodyContentContainerRef}
              className={`body-content-container ${isMobileLandingPage && "mobile-landing"}`}
            >
              <PageChange loading={pageLoading || loading} />

              <div id="mainBody" className="inner-body-container">
                <Component
                  {...pageProps}
                  setLoading={idfn} // deprecated
                  setMiniSide={setMiniSide}
                  chat={chat}
                  caseBattlesSocket={caseBattlesSocket}
                />
                {!isMobileLandingPage && (
                  <Footer feed={shouldShowFooter} isChallenges={isChallenges} />
                )}
              </div>
            </div>

            {!isMobileLandingPage && chat && (
              <Chat
                close={openChat}
                userDetails={userDetails}
                isMod={userDetails?.moderator}
                userLogged={userLogged}
                chatSocket={chatSocket}
                lang={localStorage.getItem("lang")}
              />
            )}
          </MainBodyWrapper>
          {!isMobileLandingPage && (
            <>
              <NavSideBar
                miniSide={miniSide}
                menuToggle={menuToggle}
                mobileToggle={setMenuToggle}
              />
              {!sports && <IntercomCustomButton isChatOpen={chat} />}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default function App(props) {
  const {
    pageProps: { session },
  } = props;

  const production = process.env.NEXT_PUBLIC_PRODUCTION;

  return (
    <ErrorBoundary>
      <Providers session={session}>
        {production !== "false" && (
          <Script src={process.env.NEXT_PUBLIC_MOUSEFLOW_URL} />
        )}

        {production !== "false" && <MicrosoftClarity />}

        <BreadcrumbsSchema />
        <Tracking />
        <Turnstile />
        <JotaiHydration raffleData={props.pageProps.raffleData} />
        <MyApp {...props} />
      </Providers>
    </ErrorBoundary>
  );
}
