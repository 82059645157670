import { usePathname } from "next/navigation";
import { useMemo } from "react";

const allowList = ["/casino"];

export const useShouldShowFooterFeed = () => {
  const pathname = usePathname();

  const should = useMemo(
    () =>
      allowList.some((path) => pathname?.includes?.(path)) || pathname === "/",
    [pathname]
  );

  return should;
};

export default allowList;
