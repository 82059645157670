import StorageService from "@lib/services/Storage.Service";
import Script from "next/script";
import { useEffect, useRef } from "react";
import { logError } from "../lib/tools/logger";

const sitekey = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY;

const Turnstile = () => {
  const widgetID = useRef();

  useEffect(() => {
    if (!sitekey) return;

    window.onloadTurnstileCallback = function () {
      if (window.turnstile) {
        try {
          widgetID.current = window.turnstile.render("#captcha-container", {
            sitekey,
            callback: function (token) {
              StorageService.setCookieValue("turnstile_token", token);
            },
            "error-callback": function (error) {
              console.log("Turnstile error:", error);
            },
            "refresh-expired": "never",
          });
          if (!widgetID.current) {
            throw new Error(
              `turnstile.render return widgetID=${widgetID.current}`
            );
          }
        } catch (error) {
          console.error("Error rendering Turnstile:", error);
        }
      } else {
        console.error("Turnstile is not available on the window object.");
      }

      return () => {
        window.turnstile?.remove(widgetID.current || "");
        widgetID.current = undefined;
      };
    };
  }, []);

  const scriptLink =
    "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback";

  if (!sitekey) return null;

  return (
    <>
      <div
        id="captcha-container"
        style={{
          display: "none",
        }}
      ></div>
      <Script
        defer
        async
        src={scriptLink}
        id="Turnstile-script"
        onError={logError}
      ></Script>
    </>
  );
};

export default Turnstile;
