import { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";

import { useUserSession } from "./useUserSession";
import { log } from "@lib/tools/logger";

const makeSocket = ({ namespace, publicId }) => {
  return io(namespace, {
    withCredentials: true,
    path: "/socket.io",
    transports: ["websocket"],
    secure: true,
    extraHeaders: {
      "X-Request-With": "HttpFront",
      "x-public-id": publicId,
    },
    auth: {},
    query: {},
  });
};

const sockets = {};

/**
 * Custom hook for managing a socket connection.
 *
 * @param {string|boolean} namespace - The namespace for the socket connection.
 * @param {boolean} hasAuth - Indicates whether the socket connection requires authentication.
 * @returns {import("socket.io-client").Socket|null} The socket connection object or null if the namespace is not provided.
 */
export default function useSocket(namespace = false) {
  const [socket, setSocket] = useState(sockets[namespace] || null);
  const { token, publicId } = useUserSession();

  useEffect(() => {
    if (!namespace) return;

    const skt = makeSocket({ namespace, publicId });
    setSocket(() => skt);
    sockets[namespace] = skt;
  }, [namespace, publicId]);

  useEffect(() => {
    if (!token) return;
    // do not update the socket if the token exists already
    if (socket?.auth?.token) return;

    setSocket((s) => {
      if (!s) return s;
      s.disconnect();
      s.io.opts.query.token = token;
      s.auth.token = token;
      s.connect();
      return s;
    });
  }, [socket?.auth?.token, token]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => log(`Socket ${namespace} connected`, sockets));
    socket.on("disconnect", () =>
      log(`Socket ${namespace} disconnected`, sockets),
    );

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [namespace, socket]);

  return socket;
}

export const useEvent = (socket, event, callback) => {
  useEffect(() => {
    socket?.on(event, callback);

    return () => {
      socket?.off(event, callback);
    };
  }, [socket, event, callback]);
};

export const useLiveFeed = ({
  limit = 12,
  initialState = null,
  handleMerge = () => true,
}) => {
  const socket = useSocket(
    `${process.env.NEXT_PUBLIC_API_NODE_URL}/game-history`,
    true,
  );
  const [state, setState] = useState(initialState);

  const onNewHistory = useCallback(
    (item) => {
      setState((prev) => {
        if (!prev?.length) return prev;

        if (!handleMerge({ data: item })) return prev;
        const isUnique = prev.every((i) => i.id !== item.id);
        if (!isUnique) return prev;

        return [item, ...prev].slice(0, limit);
      });
    },
    [handleMerge, limit],
  );

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  useEffect(() => {
    socket?.on("new-history", onNewHistory);

    return () => {
      socket?.off("new-history", onNewHistory);
    };
  }, [onNewHistory, socket]);

  const data = state?.slice?.(0, limit) || null;

  return { data, socket };
};
