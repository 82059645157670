import popupAtom from "../recoil/popup";
import { useSetRecoilState } from "recoil";
import { useCallback } from "react";
import useMobile from "./useMobile";

const MOBILE_DURATION = 2500;
const DEFAULT_DURATION = 5000;

export default function usePopup() {
  const isMobile = useMobile({
    breakPointOne: 1100,
    breakPointTwo: 525,
  });
  const setPopupMessages = useSetRecoilState(popupAtom);

  const setMessage = useCallback(
    (message, duration = DEFAULT_DURATION) => {
      setPopupMessages((oldMessages) => {
        const isDuplicate = oldMessages.some(
          (msg) => msg.text === message.text && msg.type === message.type
        );

        if (isDuplicate) {
          return oldMessages;
        }

        return [
          ...oldMessages,
          { ...message, duration: isMobile ? MOBILE_DURATION : duration },
        ];
      });
    },
    [isMobile, setPopupMessages]
  );
  return setMessage;
}
