import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import useSocketBattles from "./useSocketBattles";
import _PubSub from "pubsub-js";

const useSocketManager = () => {
  const [connect, setConnect] = useState(false);
  const router = useRouter();
  const asPath = router.asPath;
  // Determine the URL to pass based on the current path and connection state
  const socketURL = connect
    ? process.env.NEXT_PUBLIC_CASEBATTLES_SOCKET_URL
    : false;

  const socket = useSocketBattles(socketURL);
  // useSocketBattles

  // const socket = useSocket(socketURL, true);
  //Checks for the proper url and sets the connect state
  useEffect(() => {
    // Determine if the current URL matches the desired path
    const pathMatches = asPath.startsWith("/casino/originals/case-battles");
    // console.log("Path matches", pathMatches);
    if (pathMatches) {
      setConnect(pathMatches);
    }
  }, [asPath]);

  //Check for disconnect here
  useEffect(() => {
    if (socket && connect) {
      const pathMatches = asPath.startsWith("/casino/originals/case-battles");

      //I want to call disconnect first before removing the url
      if (!pathMatches) {
        console.log("Disconnecting battles socket");
        socket.disconnect();
        setConnect(false);
      }
    }
  }, [socket, connect, asPath]);

  //Publish Changes
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        _PubSub.publish("CASE_BATTLES_SOCKET", socket);
      });
      socket.on("disconnect", () => {
        console.log("publish Socket disconnected");
        _PubSub.publish("CASE_BATTLES_SOCKET", socket);
      });
    }
  }, [socket]);

  _PubSub.publish("CASE_BATTLES_SOCKET", socket);
  // console.log("Socket", socket, "Connect", connect, "URL", socketURL);
  return socket; // This component does not render anything
};

export default useSocketManager;
