import Script from "next/script";
import { useUserSession } from "@hooks/useUserSession";
import { useEffect } from "react";

const MicrosoftClarity = () => {
  const { publicId } = useUserSession();

  useEffect(() => {
    if (publicId && window.clarity) {
      window.clarity("identify", `${publicId}`);
      window.clarity("set", "Public ID", `${publicId}`);
    }
  }, [publicId]);

  return (
    <Script
      id="microsoft-clarity-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "ms3v6a0czw");
                `,
      }}
    />
  );
};

export default MicrosoftClarity;
