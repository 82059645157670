import { useState, useEffect, useCallback } from "react";
import { isMobileUserAgent, getDevicePixelRatio } from "@lib/tools/helpers";
import PubSub from "../lib/pubsub";

type UseMobileOptions = {
  breakPointOne?: number;
  breakPointTwo?: number;
  breakPointThree?: number | boolean;
  breakPointFour?: number | boolean;
  targetElement?: string;
  deviceCheck?: boolean;
  pixelDensity?: boolean;
};

type ViewportState =
  | "mobile-four"
  | "mobile-three"
  | "mobile-two"
  | "mobile-one"
  | false;

/**
 * Custom hook to determine the current viewport size and return a corresponding value.
 */
const useMobile = ({
  breakPointOne = 1100,
  breakPointTwo = 768,
  breakPointThree = false,
  breakPointFour = false,
  targetElement = "mainBody",
  deviceCheck = false,
  pixelDensity = false,
}: UseMobileOptions = {}): ViewportState => {
  const [viewport, setViewport] = useState<ViewportState>(
    PubSub.state[PubSub.EVENTS.MOBILE] ? "mobile-three" : false
  );

  const checkWidth = useCallback(() => {
    const mainBody = document.getElementById(targetElement) ?? document.body;

    if (mainBody) {
      let mainBodyWidth = mainBody.clientWidth + 8;

      if (pixelDensity) {
        const devicePixelRatio = getDevicePixelRatio();
        mainBodyWidth *= devicePixelRatio;
      }

      if (
        typeof breakPointFour === "number" &&
        mainBodyWidth <= breakPointFour
      ) {
        setViewport("mobile-four");
      } else if (
        typeof breakPointThree === "number" &&
        mainBodyWidth <= breakPointThree
      ) {
        setViewport("mobile-three");
      } else if (mainBodyWidth <= breakPointTwo) {
        setViewport("mobile-two");
      } else if (mainBodyWidth <= breakPointOne) {
        setViewport("mobile-one");
      } else if (deviceCheck && isMobileUserAgent()) {
        setViewport("mobile-three");
      } else {
        setViewport(false);
      }
    }
  }, [
    targetElement,
    pixelDensity,
    breakPointFour,
    breakPointThree,
    breakPointTwo,
    breakPointOne,
    deviceCheck,
  ]);

  useEffect(() => {
    const resizeObserverCallback = (entries: ResizeObserverEntry[]) => {
      // eslint-disable-next-line no-unused-vars
      for (const entry of entries) {
        checkWidth();
      }
    };

    const mainBody = document.getElementById("mainBody");
    if (!mainBody) return;

    const resizeObserver = new ResizeObserver(resizeObserverCallback);
    resizeObserver.observe(mainBody);

    checkWidth();

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkWidth]);

  return viewport;
};

type UseBreakpointOptions = {
  breakPointOne?: number;
  breakPointTwo?: number;
  breakPointThree?: number | boolean;
  breakPointFour?: number | boolean;
};

export const useBreakpoint = ({
  breakPointOne = 1100,
  breakPointTwo = 768,
  breakPointThree = false,
  breakPointFour = 390,
}: UseBreakpointOptions = {}): number => {
  const defaultWidthServer = PubSub.state[PubSub.EVENTS.MOBILE] ? 0 : 1920;
  const getWidth = useCallback(
    () =>
      typeof window !== "undefined" ? window.innerWidth : defaultWidthServer,
    [defaultWidthServer]
  );

  const [width, setWidth] = useState<number>(getWidth());

  const listener = useCallback(() => {
    setWidth(getWidth());
  }, [getWidth]);

  useEffect(() => {
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [listener]);

  if (typeof breakPointThree === "number" && width <= breakPointThree) {
    return 3;
  } else if (typeof breakPointFour === "number" && width <= breakPointFour) {
    return 4;
  } else if (width <= breakPointTwo) {
    return 2;
  } else if (width <= breakPointOne) {
    return 1;
  } else {
    return 0;
  }
};

export default useMobile;
