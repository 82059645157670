import StorageService from "@lib/services/Storage.Service";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useState, useRef } from "react";

const Tracking = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Track page view event
      window._mpevt.push(['RainBet._event', 'Pageview', '0', '43200']);
    }
  }, []);

  return (
    <>
      <Head>
        <meta httpEquiv="Delegate-CH" content="sec-ch-ua https://ads.trafficjunky.net; sec-ch-ua-arch https://ads.trafficjunky.net; sec-ch-ua-full-version-list https://ads.trafficjunky.net; sec-ch-ua-mobile https://ads.trafficjunky.net; sec-ch-ua-model https://ads.trafficjunky.net; sec-ch-ua-platform https://ads.trafficjunky.net; sec-ch-ua-platform-version https://ads.trafficjunky.net;" />
      </Head>
      <Script
        id="tracking-snippet"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
            __html: `
                var _mpevt = _mpevt || [];
                (function() {
                    var u = (("https:" == document.location.protocol) ? "https://static.trafficjunky.com/js/mp.min.js" : "http://static.trafficjunky.com/js/mp.min.js");
                    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
                    g.type = 'text/javascript'; g.defer = true; g.async = true; g.src = u;
                    s.parentNode.insertBefore(g, s);
                })();
            `,
        }}
      />
    </>
  );
};

export default Tracking;
