export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

const GoogleTagManagerBody = () =>
  GTM_ID && (
    <noscript>
      {/* Google Tag Manager (noscript) */}
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
      {/* End Google Tag Manager (noscript) */}
    </noscript>
  );

export default GoogleTagManagerBody;
