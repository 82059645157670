import { useEffect, useState } from "react";
import io from "socket.io-client";

import { useUserSession } from "./useUserSession";

/**
 * Custom hook for managing a socket connection.
 * @param {string|boolean} namespace - The namespace for the socket connection.
 * @returns {Socket|null} The socket instance or null if not connected.
 */
export default function useSocketBattles(namespace = false) {
  const [socket, setSocket] = useState(false);
  const { token, isGuest } = useUserSession();

  // console.log("is guest lobby socket", isGuest, token);

  useEffect(() => {
    if (!namespace) return;

    //If not a guest, wait until token is available
    if (!isGuest && !token) return;

    const skt = io(namespace, {
      withCredentials: true,
      extraHeaders: {
        "X-Request-With": "HttpFront",
      },
      query: {
        ...(token ? { token: token } : {}),
      },
      auth: {},
    });
    setSocket(() => skt);

    return () => {
      skt?.disconnect();
      setSocket(null);
    };
  }, [namespace, isGuest, token]);

  useEffect(() => {
    if (!token) return;

    setSocket((s) => {
      if (!s) return s;
      s.disconnect();
      s.io.opts.query.token = token;
      s.auth.token = token;
      s.connect();
      return s;
    });
  }, [token]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => console.log("Socket connected", ...arguments));
    socket.on("disconnect", () =>
      console.log("Socket disconnected", ...arguments)
    );

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [socket]);

  return socket;
}
