import { useUserSession } from "@hooks/useUserSession";
import { NODE_API } from "@lib/api/api";
import useSWR from "swr";
import { getDeviceInfo } from "@lib/tools/helpers";
import { log } from "@lib/tools/logger";

interface IP {
  id: number;
  userId: null;
  ip: string;
  cityName: string;
  region: string;
  regionName: string;
  country: string;
  countryName: string;
  constraint: string;
  firstSeen: number;
  lastSeen: number;
  proxy: string;
  hosting: string;
}

export interface Session {
  id_session: string;
  status: string;
  device: Device;
  ip: IP;
  created_at: string;
  updated_at: string;
}

export interface Device {
  id_device: string;
  browser_name: string;
  browser_version: string;
  os_name: string;
  os_version: string;
  device_type: string;
}

export const useSessions = () => {
  const { token, hasToken } = useUserSession();

  const swr = useSWR<Session[]>(
    hasToken ? ["v1/auth/sessions", token] : null,
    ([url, token]) =>
      NODE_API.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(
        (res) => res.data
      ),
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    }
  );

  return swr;
};

export type SessionData = {
  id_device: string;
  browser_name: string;
  browser_version: string;
  os_name: string;
  os_version: string;
  device_type: string;
};

export const getSessionData = async () => {
  const { id, browser, os, platform } = await getDeviceInfo();

  const data = {
    id_device: id,
    browser_name: browser.name,
    browser_version: browser.version,
    os_name: os.name,
    os_version: os.version,
    device_type: platform.type,
  } satisfies SessionData;

  return data;
};

export const saveSession = async (token: string) => {
  if (!token) throw new Error("No token provided");

  const data = await getSessionData();
  console.log("saveSession", data);

  const response = await NODE_API.post("v1/auth/sessions", data, {
    headers: { Authorization: `Bearer ${token}`, "x-token": token },
  }).then((res) => res.data);

  log(`Saved Session #${data.id_device}`, {
    token,
    data,
    response,
  });

  return true;
};
