import { useHydrateAtoms } from "jotai/utils";

//Raffle
import {
  raffleDetailsAtomBase,
  userRaffleDataAtomBase,
} from "@Games/Raffle/jotai-raffle";

//Used to hydrate Jotai atoms before the page renders
export default function JotaiHydration({ raffleData }) {
  useHydrateAtoms([
    [raffleDetailsAtomBase, raffleData?.raffle],
    [
      userRaffleDataAtomBase,
      { ticketCount: raffleData?.user?.tickets?.length },
    ],
  ]);
}
