// Create a new file for your atoms, e.g., atoms.js
import { atom } from "jotai";

// Create an atom for the time offset
export const timeOffsetAtom = atom(0);
// Tracks the general raffle data
export const raffleDetailsAtom = atom((get) => get(raffleDetailsAtomBase));
export const raffleDetailsAtomBase = atom(null);

//Tracks user raffle data
export const userRaffleDataAtom = atom((get) => get(userRaffleDataAtomBase));
export const userRaffleDataAtomBase = atom(null);
