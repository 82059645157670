import { signOut } from "next-auth/react";
import PubSub, { EVENTS } from "../pubsub";
import TokenService from "../services/Token.Service";
import { parseJwt } from "../tools/helpers";
import API from "./api";

let refreshPromise = null;

export const resetAll = async () =>
  signOut({ redirect: false, callbackUrl: "/" }).then((...args) => {
    console.log("LOGGING OUT API----------", ...args);

    if (window?.Intercom) window.Intercom("shutdown");

    TokenService.removeUser();
    localStorage.clear();

    window.location.replace("/");

    return 1;
  });

const refreshToken = async () => {
  console.log("NEW REFRESH TRIGGERED****", new Date().toLocaleString());

  const decoded = parseJwt(TokenService.getLocalAccessToken());

  if (!decoded?.public_id) {
    PubSub.publish(EVENTS.REFRESH, "error");
    localStorage.setItem("refresh-status", "error");
    return null;
  }

  PubSub.publish(EVENTS.REFRESH, "pending");
  localStorage.setItem("refresh-status", "pending");

  refreshPromise =
    refreshPromise ||
    API.post("user/refresh", {
      public_id: decoded?.public_id,
      refresh_token: TokenService.getLocalRefreshToken(),
    })
      .then((rs) => {
        if (!rs?.data?.tokens) {
          PubSub.publish(EVENTS.REFRESH, "error");
          console.warn("Refresh token request failed", rs);
          return null;
        }

        console.log("NEW TOKEN VALUE: ", rs.data.tokens);
        TokenService.setUser(rs.data.tokens);
        PubSub.publish(EVENTS.REFRESH, rs.data);
        localStorage.setItem("refresh-status", "success");

        return rs.data;
      })
      .catch((error) => {
        console.log("Refresh token request failed", error);
        PubSub.publish(EVENTS.REFRESH, "error");
        localStorage.setItem("refresh-status", "error");

        throw error;
      })
      .finally(() => {
        refreshPromise = null; // Reset the promise to allow for future refresh attempts
        localStorage.setItem("refresh-latest", Date.now().toLocaleString());
      });

  return refreshPromise;
};

export default refreshToken;
