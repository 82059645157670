import { Provider } from "jotai";
import { SessionProvider } from "next-auth/react";
import React from "react";
import { IntercomProvider } from "react-use-intercom";
import { RecoilRoot } from "recoil";

// DO NOT USE RECOIL

export const defaultIntercomData = {
  api_base: "https://api-iam.intercom.io",
  app_id: "w3maa0h3",
  hideDefaultLauncher: true,
};

const Providers = ({ children, session }) => {
  const refetchInterval = Number(
    process.env.NEXT_PUBLIC_MAX_TOKEN_AGE || 25 * 60
  );

  return (
    <Provider>
      <RecoilRoot>
        <SessionProvider
          {...(session ? { session } : {})}
          refetchInterval={refetchInterval - 5}
        >
          <IntercomProvider
            autoBoot
            appId={defaultIntercomData.app_id}
            autoBootProps={{ hideDefaultLauncher: true }}
            apiBase={defaultIntercomData.api_base}
          >
            {children}
          </IntercomProvider>
        </SessionProvider>
      </RecoilRoot>
    </Provider>
  );
};

export default Providers;
