import * as React from "react";
import { useRouter } from "next/router";

/**
 * Custom React hook that prevents Next.js from removing expired CSS stylesheets.
 * It registers and maintains a hash map of CSS stylesheets based on the page URL.
 * When the page URL changes, it removes any expired stylesheets from the DOM.
 * https://github.com/vercel/next.js/issues/33286
 * https://github.com/vercel/next.js/issues/17464#issuecomment-1537262075
 *
 * @returns {Function} A function that removes expired stylesheets from the DOM.
 */
export const useNextCssRemovalPrevention = () => {
  const { events } = useRouter();
  const fullAsPath = useFullAsPath();
  const cssHashMap = React.useRef(new Map());
  const currentUrl = React.useRef(null);
  const previousUrl = React.useRef(null);
  const referenceNode = React.useRef(null);

  const registerCssHash = React.useCallback((pageUrl, href) => {
    if (pageUrl && href) {
      const hashMap = cssHashMap.current;
      const hashes = hashMap.get(pageUrl) ?? new Set();
      const hash = getHash(href);
      if (hash && !hashes.has(hash)) {
        hashes.add(hash);
        hashMap.set(pageUrl, hashes);
      }
    }
  }, []);

  const getActiveStyleNodes = React.useCallback(() => {
    const linkNodesSelector = "head > link[data-n-p-perm]";
    const styleNodesSelector = "head > style[data-n-href-perm]";
    const nodes = querySelectorAllArray(
      [linkNodesSelector, styleNodesSelector].join(",")
    );
    return nodes.map((node) => {
      const href =
        node.nodeName === "LINK"
          ? node.getAttribute("href")
          : node.getAttribute("data-n-href-perm");
      const hash = getHash(href);
      return { node, hash };
    });
  }, []);

  const getActiveCssHashes = React.useCallback(() => {
    const currentHashes = currentUrl.current
      ? cssHashMap.current.get(currentUrl.current) ?? new Set()
      : new Set();
    const previousHashes = previousUrl.current
      ? cssHashMap.current.get(previousUrl.current) ?? new Set()
      : new Set();
    return [...currentHashes, ...previousHashes];
  }, []);

  const removeExpiredStyles = React.useCallback(() => {
    const activeCssHashes = getActiveCssHashes();
    getActiveStyleNodes().forEach(({ node, hash }) => {
      if (!activeCssHashes.includes(hash)) {
        node.parentNode?.removeChild(node);
      }
    });
  }, [getActiveStyleNodes, getActiveCssHashes]);

  React.useEffect(() => {
    if (fullAsPath && !currentUrl.current) {
      currentUrl.current = fullAsPath;
      // For all <link href data-n-p> nodes (server rendered stylesheets):
      // - rename the data-n-p attribute to data-n-p-perm
      //   (this prevents Next.js from removing them)
      // - register the css hash
      // - move the node below the reference node (maintaining order)
      querySelectorAllArray("head > link[data-n-p]")
        .reverse()
        .forEach((linkNode) => {
          linkNode.removeAttribute("data-n-p");
          linkNode.setAttribute("data-n-p-perm", "");
          registerCssHash(fullAsPath, linkNode.getAttribute("href"));
          moveNodeBelowReferenceNode(linkNode);
        });
    }
  }, [fullAsPath, registerCssHash]);

  React.useEffect(() => {
    const handleRouteChangeStart = (url) => {
      referenceNode.current = getReferenceNode();
      previousUrl.current = currentUrl.current;
      currentUrl.current = url;
    };
    const handleRouteChangeComplete = () => {
      removeExpiredStyles();
      previousUrl.current = null;
    };
    events.on("routeChangeStart", handleRouteChangeStart);
    events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      events.off("routeChangeStart", handleRouteChangeStart);
      events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [events, removeExpiredStyles]);

  React.useEffect(() => {
    const mutationHandler = (mutations) => {
      mutations.forEach(({ target, addedNodes }) => {
        if (target.nodeName === "HEAD") {
          // For all newly added <style data-n-href> nodes (page stylesheets):
          // - rename the data-n-href attribute to data-n-href-perm
          //   (This prevents Next.js from removing or disabling them)
          // - remove the media attribute if its value is "x"
          // - register the css hash
          // - move the node below the reference node (maintaining order)
          addedNodes.forEach((node) => {
            const el = node;
            if (el.nodeName === "STYLE" && el.hasAttribute("data-n-href")) {
              const href = el.getAttribute("data-n-href") ?? "";
              el.setAttribute("data-n-href-perm", href);
              el.removeAttribute("data-n-href");
              if (el.getAttribute("media") === "x") {
                el.removeAttribute("media");
              }
              registerCssHash(currentUrl.current, href);
              moveNodeBelowReferenceNode(el);
              referenceNode.current = el;
            }
          });
        }
      });
    };

    // Observe changes to the head element and its descendents.
    const observer = new MutationObserver(mutationHandler);
    observer.observe(document.head, { childList: true, subtree: true });

    return () => {
      // Disconnect the observer when the component unmounts.
      observer.disconnect();
    };
  }, [registerCssHash]);

  return removeExpiredStyles;
};

function getHash(url) {
  return url?.match(/([a-z0-9]+).css$/)?.[1] ?? null;
}

function querySelectorAllArray(selector) {
  return Array.from(document.querySelectorAll(selector));
}

function getReferenceNode() {
  return document.querySelector("noscript[data-n-css]");
}

function moveNodeBelowReferenceNode(node) {
  const referenceNode = getReferenceNode();
  referenceNode?.parentNode?.insertBefore(node, referenceNode?.nextSibling);
}

function useFullAsPath() {
  const { isReady, asPath, basePath, locale } = useRouter();
  const getFullAsPath = React.useCallback(() => {
    if (!isReady) return null;
    let result = asPath;
    if (basePath) result = basePath + (result === "/" ? "" : result);
    if (locale) result = `/${locale}${result}`;
    return result;
  }, [asPath, isReady, basePath, locale]);
  const [fullAsPath, setFullAsPath] = React.useState(getFullAsPath());
  React.useEffect(() => {
    setFullAsPath(getFullAsPath());
  }, [getFullAsPath]);
  return fullAsPath;
}
