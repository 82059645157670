import FacebookPixel from "./FacebookPixel";
import GoogleAnalyticsGA4 from "./GoogleAnalyticsGA4";
import GoogleTagManagerBody from "./GoogleTagManagerBody";
import GoogleTagManagerHead from "./GoogleTagManagerHead";
import AnjSeal from "./AnjSeal";

const IntegrationComponent = {
  FacebookPixel,
  GoogleAnalyticsGA4,
  GoogleTagManagerBody,
  GoogleTagManagerHead,
  AnjSeal,
};

export default IntegrationComponent;
