import usePopup from "@hooks/usePopup";
import { useUserSession } from "@hooks/useUserSession";
import { NODE_API } from "@lib/api/api";
import { handleCatchPopup } from "@lib/tools/helpers";
import { pageLoadingAtom } from "@store/pageLoading";
import { useAtom } from "jotai";
import Script from "next/script";
import { useCallback, useEffect, useState } from "react";

const GoogleSignIn = () => {
  const { hasToken, signInToken } = useUserSession();
  const setMessage = usePopup();
  const [, setPageLoading] = useAtom(pageLoadingAtom);
  const [isLoaded, setIsLoaded] = useState(
    typeof window !== "undefined" && "google" in window
  );

  const callback = useCallback(
    ({ credential }) => {
      setPageLoading(true);
      NODE_API.get("/v1/auth/google/callback_fedcm", {
        headers: {
          Authorization: `Bearer ${credential}`,
        },
      })
        .then((r) => r.data.tokens)
        .then(signInToken)
        .catch(handleCatchPopup(setMessage, true))
        .finally(() => setPageLoading(false));
    },
    [setMessage, setPageLoading, signInToken]
  );

  useEffect(() => {
    if (!isLoaded || hasToken) return;

    window.google.accounts.id.initialize({
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      callback,
      log_level: "debug",
      use_fedcm_for_prompt: true,
      cancel_on_tap_outside: false,
    });

    window.google.accounts.id.prompt();
  }, [isLoaded, hasToken, callback]);

  if (hasToken) return null;

  return (
    <>
      <Script
        id="google-sign-in"
        src="https://accounts.google.com/gsi/client"
        onLoad={() => setIsLoaded(true)}
      />
    </>
  );
};

export default GoogleSignIn;
